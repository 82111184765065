import React, { PureComponent } from 'react'
import {
    MessageBarType, TextField, ICommandBarItemProps, Text, Label, Toggle, Dialog, DialogType, Spinner, SpinnerSize, PrimaryButton, DefaultButton, DialogFooter,
} from '@fluentui/react'
import history from 'helpers/history'
// eslint-disable-next-line import/named
import { AppProps } from 'app'
import Status from 'types/status'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import Card from 'components/containers/card'
import Loader from 'components/visuals/loader'
import CancelRequestError from 'requests/errors/cancelRequestError'
import NotImplementedError from 'requests/errors/notImplementedError'
import FilteredVirtualCombobox from 'components/inputs/filteredVirtualCombobox'
import FilteredVirtualComboboxAjax from 'components/inputs/filteredVirtualComboboxAjax'
import { isValidDate } from 'helpers/methods/common'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import { Columns } from 'react-bulma-components'
import { NEW_PATH } from 'types/others'
import CommercialInformation, { ErrorCommercialInformation } from 'requests/objects/commercialInformation'
import Time from 'helpers/methods/time'
import ProcessModal from 'components/pages/commercial-informations/[id]/processModal'
import EProfile from 'types/profile'
import { Link } from 'react-router-dom'
import CvInput from 'components/pages/candidats/[id]/cvInput'
import setPageTitle from 'helpers/methods/pageTitle'
import resetCarret from 'helpers/methods/resetCarret'

/** @debug {AppProps} */

/**
 * @augments {PureComponent<AppProps>}
 */
export default class IdCommercialInformations extends PureComponent {
    constructor(props) {
        super(props)
        const { match } = this.props
        this.state = {
            /** @type {Status} Current status of the component */
            status: Status.IDLE,
            /** @type {boolean} Is in readonly */
            isReadOnly: !!match?.params?.commercialInformationId,
            /** @type {CommercialInformation} Element find from API */
            item: new CommercialInformation(),
            /** @type {CommercialInformation} Initial element fond from API. Used to refresh */
            iniItem: new CommercialInformation(),
            /** @type {ErrorCommercialInformation} Errors */
            errorField: new ErrorCommercialInformation(),
            /** @type {boolean} Is process modal visible? */
            isProcessModalVisible: false,
            /** @type {boolean} show consultant dialog? */
            showConsultantDialog: false,
            /** @type {boolean} is assigning consultant? */
            isAssigningConsultant: false,
            /** @type {number} new consultant id */
            newConsultantId: null,
        }

        this.submitInput = React.createRef()
    }

    /**
     * @inheritdoc
     */
    componentDidMount() {
        this.init()
    }

    /**
     * @inheritdoc
     * @param {object} prevProps Previous Props
     */
    componentDidUpdate(prevProps) {
        const { match, setCommand } = this.props

        if (match?.params?.commercialInformationId !== prevProps?.match?.params?.commercialInformationId)
            if (!prevProps?.match.path.includes(`/${NEW_PATH}`)) {
                this.init()
            } else {
                setCommand([])
                this.setupBreadcrumb()
                this.setupCommandBar()
                setCommand(this.commandRead)
            }
    }

    /**
     * @inheritdoc
     */
    componentWillUnmount() {
        this.commercialInformationsHandlerRemoveById?.cancel()
        this.commercialInformationsHandlerGetById?.cancel()
        this.commercialInformationsHandlerUpsert?.cancel()
        this.commercialInformationsHandlerAssign?.cancel()
        this.contactsHandlerGetById?.cancel()
    }

    /**
     * Setup breadcrumb elements
     */
    setupBreadcrumb() {
        const { setBreadcrumb, match, setTitle } = this.props
        const { item } = this.state
        // eslint-disable-next-line no-nested-ternary
        const mainTitle = `Information commerciale ${match?.params?.commercialInformationId
            ? ((item.isProspect ? item.prospectName : item.client?.name) || '')
            : 'Nouveau'}`

        setBreadcrumb([
            { text: 'Saisie information commerciale', key: 'information-commerciale' },
            { text: 'Toutes les informations commerciales', key: 'all-information-commerciale', href: '/informations-commerciales' },
            {
                text: mainTitle,
                key: 'one-information-commerciale',
                isCurrentItem: true,
            },
        ])
        setTitle('Informations commerciales')
        setPageTitle(mainTitle)
    }

    /**
     * Setup commandbar elements
     */
    setupCommandBar() {
        const {
            setCommand, match, me, setModal, commercialInformationsHandler, setMessageBar,
        } = this.props

        const { item, iniItem } = this.state
        /**
         * @type {ICommandBarItemProps[]} Commanbar items when readonly
         */
        this.commandRead = []
        if (me.profileId !== EProfile.Consultant)
            this.commandRead.push({
                key: 'edit',
                text: 'Modifier',
                iconProps: { iconName: 'Edit' },
                onClick: () => this.setState({ isReadOnly: false }, () => setCommand(this.commandEdit)),
                disabled: me?.profileId === EProfile.Consultant,
            })
        if (me.profileId === EProfile.Consultant) {
            this.commandRead.push({
                key: 'affect',
                text: 'Assigner',
                iconProps: { iconName: 'ReminderPerson' },
                onClick: () => this.setState({ showConsultantDialog: true }),
                disabled: item.consultantId && me.userId !== item.consultantId,
            })
            this.commandRead.push({
                key: 'treat',
                text: item.isProcessed ? 'Modifier le traitement' : 'Traiter',
                iconProps: { iconName: 'Assign' },
                onClick: () => this.setState({ isProcessModalVisible: true }),
                disabled: !item.isPublic && me.userId !== item.consultantId,
            })
        }

        if (me.profileId === EProfile.Administrator)
            if (item.isAccepted === null) {
                this.commandRead.push({
                    key: 'Accept',
                    text: 'Accepter',
                    iconProps: { iconName: 'Accept' },
                    onClick: () => {
                        item.isAccepted = true
                        this.submitInput.current.click()
                    },
                })
                this.commandRead.push({
                    key: 'Refuse',
                    text: 'Refuser',
                    iconProps: { iconName: 'ChromeClose' },
                    onClick: () => {
                        item.isAccepted = false
                        this.submitInput.current.click()
                    },
                })
            } else {
                this.commandRead.push({
                    key: 'Accept',
                    text: item.isAccepted ? 'Refuser' : 'Accepter',
                    iconProps: { iconName: item.isAccepted ? 'ChromeClose' : 'Accept' },
                    onClick: () => {
                        item.isAccepted = !item.isAccepted
                        this.submitInput.current.click()
                    },
                })
            }

        if (me.profileId === EProfile.Administrator)
            this.commandRead.push({
                key: 'delete',
                text: 'Supprimer',
                iconProps: { iconName: 'Delete' },
                onClick: () => {
                    setModal({
                        show: true,
                        title: "Supprimer l'information commerciale",
                        subTitle: "Êtes-vous certain de vouloir supprimer l'information commerciale ? Cette action est définitive.",
                        callback: () => {
                            this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
                                setCommand([])
                                setMessageBar({ isDisplayed: false })
                                try {
                                    // eslint-disable-next-line max-len
                                    this.commercialInformationsHandlerRemoveById = commercialInformationsHandler.removeById(match?.params?.commercialInformationId)
                                    await this.commercialInformationsHandlerRemoveById.fetch()
                                    history.push('/informations-commerciales')
                                    setMessageBar({ // We must set the messagebar after change page, to force re add it
                                        isDisplayed: true,
                                        type: MessageBarType.success,
                                        message: "L'information commerciale a bien été supprimé.",
                                    })
                                } catch (error) {
                                    switch (error?.constructor) {
                                        case CancelRequestError:
                                        case UnauthorizedError:
                                        case InvalidEntityError: break
                                        case NotImplementedError:
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                        default:
                                            setCommand(this.commandEdit)
                                            this.setState({ isReadOnly: false, status: Status.REJECTED })
                                            // eslint-disable-next-line no-console
                                            console.error(error)
                                            break
                                    }
                                }
                            })
                        },
                    })
                },
                disabled: item?.isProcessed,
            })
        /**
         * @type {ICommandBarItemProps[]} Commanbar items when editing
         */
        this.commandEdit = [
            {
                key: 'cancel',
                text: 'Annuler',
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    this.setState(
                        {
                            isReadOnly: true,
                            item: iniItem,
                            status: Status.PENDING,
                            errorField: new ErrorCommercialInformation(),
                        },
                        () => this.setState({ status: Status.IDLE }, // Workaround to reset input with "defaultValue"
                            () => setCommand(this.commandRead)),
                    )
                },
                disabled: !match?.params?.commercialInformationId,
            },
            {
                key: 'save',
                text: 'Enregistrer',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    this.submitInput.current.click()
                },
            },
        ]
    }

    /**
     * Init Page
     */
    init() {
        const {
            setCommand, match, location, commercialInformationsHandler,
        } = this.props
        setCommand([])
        // If there is an id in URL, get element by id
        if (match?.params?.commercialInformationId)
            this.setState({ status: Status.PENDING }, async () => {
                if (location.state?.commercialInformation) // If object came from history push with a create
                    this.setState({
                        item: location.state?.commercialInformation,
                        iniItem: location.state?.commercialInformation,
                        status: Status.RESOLVED,
                    }, () => {
                        this.setupBreadcrumb()
                        this.setupCommandBar()
                        setCommand(this.commandRead)
                        this.setupBreadcrumb()
                    })
                else
                    try {
                        this.commercialInformationsHandlerGetById = commercialInformationsHandler.getById(match?.params?.commercialInformationId)
                        const commercialInformation = await this.commercialInformationsHandlerGetById.fetch()
                        this.setState({
                            item: commercialInformation,
                            iniItem: commercialInformation,
                            status: Status.RESOLVED,
                        }, () => {
                            this.setupBreadcrumb()
                            this.setupCommandBar()
                            setCommand(this.commandRead)
                            this.setupBreadcrumb()
                        })
                    } catch (error) {
                        switch (error?.constructor) {
                            case CancelRequestError:
                            case UnauthorizedError:
                            case InvalidEntityError: break
                            case NotImplementedError:
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                            default:
                                setCommand(this.commandRead)
                                this.setState({ status: Status.REJECTED })
                                // eslint-disable-next-line no-console
                                console.error(error)
                                break
                        }
                    }
            })
        else
            this.setState({ status: Status.PENDING }, () => {
                this.setState({
                    item: new CommercialInformation(),
                    iniItem: new CommercialInformation(),
                    status: Status.IDLE,
                }, () => {
                    this.setupCommandBar()
                    setCommand(this.commandEdit)
                })
            })
    }

    /**
     * Function called when valide form in submit, in order to save or create new entity
     */
    upsert() {
        const {
            setCommand, commercialInformationsHandler, match, setMessageBar,
        } = this.props
        const { item } = this.state

        this.setState({ status: Status.PENDING, isReadOnly: true }, async () => {
            setCommand([])
            setMessageBar({ isDisplayed: false })
            try {
                this.commercialInformationsHandlerUpsert = commercialInformationsHandler.upsert(item, match?.params?.commercialInformationId)
                const user = await this.commercialInformationsHandlerUpsert.fetch()

                this.setState({
                    item: user,
                    iniItem: user,
                    status: Status.RESOLVED,
                    errorField: new ErrorCommercialInformation(),
                })
                this.setupCommandBar()
                setCommand(this.commandRead)

                if (!match?.params?.commercialInformationId)
                    history.replace(`/informations-commerciales/${user.commercialInformationId}`)
            } catch (error) {
                this.setState({ status: Status.REJECTED, isReadOnly: false }, () => {
                    switch (error?.constructor) {
                        case CancelRequestError:
                        case UnauthorizedError: break
                        case NotImplementedError:
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                        case InvalidEntityError:
                            this.setState({ errorField: /** @type {InvalidEntityError<ErrorCommercialInformation>} */(error).errorField })
                            setCommand(this.commandEdit)
                            break
                        default:
                            setCommand(this.commandEdit)
                            // eslint-disable-next-line no-console
                            console.error(error)
                            break
                    }
                })
            }
        })
    }

    /**
     * Import data from contact
     * @param {number} contactId contactId
     */
    async importContact(contactId = 0) {
        const { contactsHandler } = this.props
        const { item } = this.state

        try {
            this.contactsHandlerGetById = contactsHandler.getById(contactId)
            const contact = await this.contactsHandlerGetById.fetch()

            this.setState({
                item: {
                    ...item,
                    contactLastname: contact.lastname,
                    contactFirstname: contact.firstname,
                    contactPhone: contact.mobilePhone,
                    contactFunction: contact.metier?.name,
                    contactEmail: contact.email,
                    divisionId: contact.divisionId,
                    subDivisionId: contact.subDivisionId,
                    metierId: contact.metierId,
                },
            })
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError: break
                case NotImplementedError:
                case InvalidEntityError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    break
            }
        }
    }

    /**
     * Render component
     * @returns {JSX.Element} Element
     */
    render() {
        const {
            item, status, isReadOnly, errorField, isProcessModalVisible,
            showConsultantDialog, isAssigningConsultant, newConsultantId,
        } = this.state
        const {
            param, setCommand, searchFiltersHandler, commercialInformationsHandler, me,
            setMessageBar,
        } = this.props

        const consultants = item.consultantId !== me.userId ? param.consultants
            : param.consultants.filter(({ key }) => key !== me.userId)

        // TODO changer la date pour mettre celle de la mise en prod
        const isOldIC = createdAt => createdAt && new Date(createdAt).getTime() < new Date('2025-01-01').getTime()
        if (status === Status.PENDING)
            return <Loader />

        return (
            <>
                <main>
                    <form
                        onSubmit={ev => {
                            ev.preventDefault()
                            this.upsert()
                        }}
                    >
                        <Card
                            title="Informations"
                            iconName="PageData"
                        >
                            <Columns>
                                {!isReadOnly && (
                                    <Columns.Column size="one-quarter">
                                        <Label
                                            htmlFor="isProspect"
                                        >
                                            Entreprise présent dans la base?
                                        </Label>
                                        <Toggle
                                            id="isProspect"
                                            onText="Oui"
                                            offText="Non"
                                            checked={!item.isProspect}
                                            onChange={(_ev, checked) => this.setState({ item: { ...item, isProspect: !checked } })}
                                        />
                                    </Columns.Column>
                                )}
                                <Columns.Column size="one-quarter">
                                    {!item.isProcessed && item.isProspect ? (
                                        <TextField
                                            label="Entreprise non présente dans la base"
                                            value={item.prospectName}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                            onChange={(ev, newVal) => {
                                                this.setState({ item: { ...item, prospectName: newVal?.toUpperCase() } })
                                                resetCarret(ev)
                                            }}
                                            errorMessage={errorField.prospectName}
                                            required={!isReadOnly}
                                        />
                                    ) : (
                                        <FilteredVirtualComboboxAjax
                                            label="Entreprise déjà présente dans la base"
                                            minChar={0}
                                            readOnly={isReadOnly}
                                            disabled={status === Status.PENDING}
                                            selectedKey={item.clientId}
                                            onChange={(_ev, option) => this.setState({ item: { ...item, clientId: option.key } })}
                                            functionName="searchClients"
                                            handler={searchFiltersHandler}
                                            errorMessage={errorField.clientId}
                                            required={!isReadOnly}
                                            // eslint-disable-next-line react/no-unstable-nested-components
                                            onRenderInput={(props, defaultRender) => (isReadOnly && item.clientId
                                                ? (
                                                    <Text styles={{ root: { padding: '6px 8px 0' } }}>
                                                        <Link to={`/clients/${item.clientId}`}>
                                                            {item.client?.name}
                                                        </Link>
                                                    </Text>
                                                )
                                                : defaultRender(props))}
                                        />
                                    )}
                                </Columns.Column>
                                {!isReadOnly && (
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualComboboxAjax
                                            label="Candidat"
                                            minChar={0}
                                            placeholder={item.candidate ? `${item.candidate?.lastname} ${item.candidate?.firstname}` : undefined}
                                            readOnly={isReadOnly}
                                            disabled={status === Status.PENDING}
                                            selectedKey={item.candidateId}
                                            onChange={(_ev, option) => this.setState({ item: { ...item, candidateId: option.key } })}
                                            functionName="searchCandidates"
                                            handler={searchFiltersHandler}
                                            errorMessage={errorField.candidateId}
                                        />
                                    </Columns.Column>
                                )}
                                {isReadOnly && me.profileId !== EProfile.OperationsManager && (
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="CR"
                                            value={`${item.createdByUser?.lastname} ${item.createdByUser?.firstname}`}
                                            readOnly
                                            borderless
                                        />
                                    </Columns.Column>
                                )}
                                {(!isReadOnly || me.profileId !== EProfile.Consultant) && (
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualCombobox
                                            label="Consultant"
                                            readOnly={isReadOnly}
                                            options={param.consultants}
                                            selectedKey={item.consultantId}
                                            onChange={(_ev, option) => this.setState({ item: { ...item, consultantId: option.key } })}
                                            errorMessage={errorField.consultantId}
                                            required={!isReadOnly}
                                        />
                                    </Columns.Column>
                                )}
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Accepté ?"
                                        // eslint-disable-next-line no-nested-ternary
                                        value={item.isAccepted === null ? '' : (item.isAccepted ? 'Oui' : 'Non')}
                                        readOnly // modifié uniquement par la commandBar
                                        borderless
                                        errorMessage={errorField.isAccepted}
                                    />
                                </Columns.Column>
                            </Columns>
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Date de l'entretien"
                                        value={item.rdvDate ? Time(item.rdvDate).getLocaleDateString() : ''}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => this.setState({
                                            item: { ...item, rdvDate: this.isValidDate(new Date(newVal)) ? new Date(newVal) : '' },
                                        })}
                                        errorMessage={errorField.rdvDate}
                                        type="date"
                                        required={!isReadOnly}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Nature de l'IC"
                                        readOnly={isReadOnly}
                                        options={param.commercialInformationNatures}
                                        selectedKey={item.commercialInformationNatureId}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, commercialInformationNatureId: option.key } })}
                                        errorMessage={errorField.commercialInformationNatureId}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <FilteredVirtualCombobox
                                        label="Département"
                                        readOnly={isReadOnly}
                                        options={param.departments}
                                        selectedKey={item.departmentId}
                                        onChange={(_ev, option) => this.setState({ item: { ...item, departmentId: option.key } })}
                                        errorMessage={errorField.departmentId}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Commentaire"
                                        value={item.comment}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        onChange={(ev, newVal) => {
                                            this.setState({ item: { ...item, comment: newVal?.toUpperCase() } })
                                            resetCarret(ev)
                                        }}
                                        errorMessage={errorField.comment}
                                        multiline
                                        autoAdjustHeight
                                    />
                                </Columns.Column>
                            </Columns>
                        </Card>
                        <br />
                        <Card
                            title="Contact"
                            iconName="ProfileSearch"
                        >
                            {!item?.commercialInformationId && item.clientId && !item.isProspect && (
                                <Columns>
                                    <Columns.Column size="one-quarter">
                                        <FilteredVirtualComboboxAjax
                                            label="Importer depuis un contact"
                                            minChar={1}
                                            readOnly={isReadOnly}
                                            disabled={status === Status.PENDING}
                                            onChange={(_ev, option) => {
                                                if (option.key)
                                                    this.importContact(option.key)
                                            }}
                                            functionName="searchContacts"
                                            handler={searchFiltersHandler}
                                            extraFilters={{ clientId: item.clientId }}
                                        />
                                    </Columns.Column>
                                </Columns>
                            )}
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Nom"
                                        value={item.contactLastname}
                                        onChange={(ev, newVal) => {
                                            this.setState({ item: { ...item, contactLastname: newVal?.toUpperCase() } })
                                            resetCarret(ev)
                                        }}
                                        errorMessage={errorField.contactLastname}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        required={!isReadOnly}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Prénom"
                                        value={item.contactFirstname}
                                        onChange={(ev, newVal) => {
                                            this.setState({ item: { ...item, contactFirstname: newVal?.toUpperCase() } })
                                            resetCarret(ev)
                                        }}
                                        errorMessage={errorField.contactFirstname}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                    />
                                </Columns.Column>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Téléphone"
                                        value={item.contactPhone}
                                        onChange={(ev, newVal) => {
                                            this.setState({ item: { ...item, contactPhone: newVal?.toUpperCase() } })
                                            resetCarret(ev)
                                        }}
                                        errorMessage={errorField.contactPhone}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        required={!isReadOnly}
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        onRenderInput={(props, defaultRender) => (
                                            props.readOnly && item.contactPhone ? (
                                                <Text styles={{ root: { padding: '6px 8px 0' } }}>
                                                    <a
                                                        href={`tel:${item?.contactPhone}`}
                                                        style={{ textDecoration: 'none' }}
                                                    >
                                                        {item.contactPhone}
                                                    </a>
                                                </Text>
                                            )
                                                : defaultRender(props))}
                                    />
                                </Columns.Column>
                                {isOldIC(item.createdAt) && (
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Fonction"
                                            value={item.contactFunction}
                                            onChange={(ev, newVal) => {
                                                this.setState({ item: { ...item, contactFunction: newVal?.toUpperCase() } })
                                                resetCarret(ev)
                                            }}
                                            errorMessage={errorField.contactFunction}
                                            readOnly={isReadOnly}
                                            borderless={isReadOnly}
                                        />
                                    </Columns.Column>
                                )}
                            </Columns>
                            <Columns>
                                <Columns.Column size="one-quarter">
                                    <TextField
                                        label="Email"
                                        value={item.contactEmail}
                                        onChange={(ev, newVal) => this.setState({ item: { ...item, contactEmail: newVal } })}
                                        errorMessage={errorField.contactEmail}
                                        readOnly={isReadOnly}
                                        borderless={isReadOnly}
                                        // eslint-disable-next-line react/no-unstable-nested-components
                                        onRenderInput={(props, defaultRender) => (
                                            isReadOnly && item.contactEmail ? (
                                                <Text styles={{ root: { padding: '6px 8px 0' } }}>
                                                    <a
                                                        href={`mailto:${item.contactEmail}`}
                                                        style={{ textDecoration: 'none' }}
                                                    >
                                                        {item.contactEmail}
                                                    </a>
                                                </Text>
                                            )
                                                : defaultRender(props))}
                                    />

                                </Columns.Column>
                                {(!item.commercialInformationId || !isOldIC(item.createdAt)) && (
                                    <>
                                        <Columns.Column size="one-quarter">
                                            <FilteredVirtualCombobox
                                                label="Famille de poste"
                                                readOnly={isReadOnly}
                                                options={param.divisions}
                                                selectedKey={item.contactDivisionId}
                                                onChange={(_ev, option) => this.setState({ item: { ...item, contactDivisionId: option.key } })}
                                                errorMessage={errorField.divisionId}
                                                required
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <FilteredVirtualCombobox
                                                label="Sous-famille"
                                                readOnly={isReadOnly}
                                                selectedKey={item.contactSubDivisionId}
                                                options={param.subDivisions.filter(x => !item.contactDivisionId || x.divisionId === item.contactDivisionId)}
                                                onChange={(_ev, option) => this.setState({ item: { ...item, contactSubDivisionId: option.key } })}
                                                errorMessage={errorField.subDivisionId}
                                                required
                                            />
                                        </Columns.Column>
                                        <Columns.Column size="one-quarter">
                                            <FilteredVirtualCombobox
                                                label="Metier"
                                                readOnly={isReadOnly}
                                                options={param.metiers.filter(x => !item.contactSubDivisionId || x.subDivisionId === item.contactSubDivisionId)}
                                                selectedKey={item.contactMetierId}
                                                onChange={(_ev, option) => this.setState({ item: { ...item, contactMetierId: option.key } })}
                                                errorMessage={errorField.metierId}
                                                required
                                            />
                                        </Columns.Column>
                                    </>
                                )}
                            </Columns>
                        </Card>
                        <br />
                        {isReadOnly && (
                            <Card
                                title="Candidat"
                                iconName="ProfileSearch"
                            >
                                <Columns>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Nom"
                                            value={item.candidate?.lastname}
                                            readOnly
                                            borderless
                                            // eslint-disable-next-line react/no-unstable-nested-components
                                            onRenderInput={(props, defaultRender) => (item.candidateId
                                                ? (
                                                    <Text styles={{ root: { padding: '6px 8px 0' } }}>
                                                        <Link to={`/candidats/${item.candidateId}`}>
                                                            {props.value}
                                                        </Link>
                                                    </Text>
                                                )
                                                : defaultRender(props))}
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Prénom"
                                            value={item.candidate?.firstname}
                                            readOnly
                                            borderless
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <TextField
                                            label="Téléphone"
                                            value={item.candidate?.mobilePhone}
                                            readOnly
                                            borderless
                                            // eslint-disable-next-line react/no-unstable-nested-components
                                            onRenderInput={(props, defaultRender) => (
                                                props.readOnly && item.contactEmail ? (
                                                    <Text styles={{ root: { padding: '6px 8px 0' } }}>
                                                        <a
                                                            href={`tel:${item.candidate?.mobilePhone}`}
                                                            style={{ textDecoration: 'none' }}
                                                        >
                                                            {item.candidate?.mobilePhone}
                                                        </a>
                                                    </Text>
                                                )
                                                    : defaultRender(props))}
                                        />
                                    </Columns.Column>
                                    <Columns.Column size="one-quarter">
                                        <CvInput
                                            isReadOnly={isReadOnly}
                                            handler={null}
                                            updateItem={() => { }}
                                            id={item.candidateId}
                                            item={item.candidate ?? {}}
                                        />
                                    </Columns.Column>
                                </Columns>
                            </Card>
                        )}
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <button
                            type="submit"
                            style={{ display: 'none' }}
                            ref={this.submitInput}
                            tabIndex={-1}
                        />
                    </form>
                </main>
                <Dialog
                    hidden={!showConsultantDialog}
                    onDismiss={() => this.setState({ showConsultantDialog: false })}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: 'Assigner un consultant',
                        subText: "Si vous assigné un autre consultant, vous n'aurez plus accès à cette information commerciale.",
                    }}
                    modalProps={{
                        isBlocking: true,
                        styles: { main: { maxWidth: 800 } },
                    }}
                    maxWidth="555px"
                    minWidth="33%"
                >
                    <FilteredVirtualCombobox
                        label="Consultant"
                        options={consultants}
                        selectedKey={newConsultantId}
                        onChange={(_ev, option) => this.setState({ newConsultantId: option.key })}
                    />
                    <br />
                    <DialogFooter>
                        <DefaultButton
                            onClick={() => this.setState({ showConsultantDialog: false })}
                            text="Annuler"
                            disabled={isAssigningConsultant}
                        />
                        <PrimaryButton
                            onClick={() => {
                                this.setState({ isAssigningConsultant: true }, async () => {
                                    try {
                                        this.commercialInformationsHandlerAssign = commercialInformationsHandler.assign(
                                            item.commercialInformationId,
                                            newConsultantId,
                                        )
                                        const ci = await this.commercialInformationsHandlerAssign.fetch()

                                        if (ci.consultantId !== me.userId)
                                            history.push('/informations-commerciales')
                                        else
                                            this.setState({
                                                showConsultantDialog: false,
                                                isAssigningConsultant: false,
                                                item: ci,
                                            })
                                        setMessageBar({
                                            isDisplayed: true,
                                            type: MessageBarType.success,
                                            message: ci.consultantId === me.userId ? "Vous vous êtes assigné à l'information commerciale."
                                                : `Vous avez assigné ${ci.consultant.lastname} ${ci.consultant.firstname} à l'information commerciale.`,
                                        })
                                    } catch (error) {
                                        this.setState({
                                            showConsultantDialog: false,
                                            isAssigningConsultant: false,
                                        })
                                    }
                                })
                                // onDelete().finally(() => this.setState({isAssigningConsultant: false, showConsultantDialog: false }))
                            }}
                            disabled={isAssigningConsultant || !newConsultantId}
                        >
                            Assigner
                            {' '}
                            {' '}
                            {isAssigningConsultant && (
                                <Spinner
                                    size={SpinnerSize.small}
                                    labelPosition="right"
                                />
                            )}
                        </PrimaryButton>
                    </DialogFooter>
                </Dialog>
                <ProcessModal
                    isVisible={isProcessModalVisible}
                    commercialInformation={item}
                    completeProcess={({ commercialInformation, commercialInformationProcessOptionId }) => {
                        if (!commercialInformation) {
                            this.setState({ isProcessModalVisible: false })
                            return
                        }
                        if (commercialInformationProcessOptionId) {
                            const queryParams = new URLSearchParams()
                            queryParams.append('commercialInformationProcessOptionId', commercialInformationProcessOptionId?.toString())
                            queryParams.append('commercialInformationId', commercialInformation.commercialInformationId?.toString())
                            queryParams.append('name', commercialInformation.prospectName)
                            history.push({
                                pathname: `/clients/${NEW_PATH}`,
                                search: `?${queryParams?.toString()}`,
                                state: {
                                    contact: {
                                        // divisionId: item.divisionId,
                                        // subDivisionId: item.subDivisionId,
                                        mobilePhone: item.contactPhone,
                                        // metierId: item.metierId,
                                        firstname: item.contactFirstname,
                                        lastname: item.contactLastname,
                                    },
                                },
                            })
                            return
                        }
                        this.setState({
                            item: commercialInformation,
                            iniItem: commercialInformation,
                            status: Status.RESOLVED,
                            errorField: new ErrorCommercialInformation(),
                            isProcessModalVisible: false,
                        }, () => {
                            this.setupCommandBar()
                            setCommand(this.commandRead)
                        })
                    }}
                    commercialInformationsHandler={commercialInformationsHandler}
                    commercialInformationProcessOptionParam={param.commercialInformationProcessOptions}
                    commercialInformationProcessOption={item.commercialInformationProcessOption}
                />
                <br />
                {
                    item.isProcessed && (
                        <Card
                            title="Traitement"
                            iconName="DonutChart"
                        >
                            <Text>
                                {item.commercialInformationProcessOption?.name}
                                {' '}
                            </Text>
                        </Card>
                    )
                }
            </>
        )
    }
}

IdCommercialInformations.prototype.isValidDate = isValidDate
