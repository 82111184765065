import React, {
    memo,
    useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import {
    IconButton, TextField,
} from '@fluentui/react'
import CandidaciesHandler from 'requests/handlers/candidaciesHandler'
import Time from 'helpers/methods/time'
import User from 'requests/objects/user'
import styles from 'styles/components/pages/missions/pivotCandidacy/boardDetailPanelComment.module.scss'
import Status from 'types/status'
// eslint-disable-next-line import/named
import { RequestApi } from 'requests/apiHandler'
import BoardComment, { ErrorBoardComment } from 'requests/objects/boardComment'
import CancelRequestError from 'requests/errors/cancelRequestError'
import UnauthorizedError from 'requests/errors/unauthorizedError'
import InvalidEntityError from 'requests/errors/invalidEntityError'
import NotImplementedError from 'requests/errors/notImplementedError'
import HuntsHandler from 'requests/handlers/huntsHandler'
import { isValidDate } from 'helpers/methods/common'

/**
 * Candidacy card
 * @param {object} props Props
 * @param {BoardComment} props.item item
 * @param {CandidaciesHandler | HuntsHandler} props.handler handler
 * @param {User} props.me me
 * @param {(comment: BoardComment) => void} props.onUpdate onUpdate
 * @param {() => void} props.onDelete onDelete
 * @returns {JSX.Element} Returns
 */
function BoardDetailPanelComment({
    item, handler, me, onUpdate, onDelete,
}) {
    const [status, setStatus] = useState(Status.IDLE)
    const [errorField, setErrorField] = useState(new ErrorBoardComment())

    /** @type {React.MutableRefObject<RequestApi<BoardComment>>} */
    const handlerUpsertComment = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<BoardComment>>} */
    const handlerRemoveComment = useRef(null)
    /** @type {React.MutableRefObject<RequestApi<BoardComment>>} */
    const handlerTogglePin = useRef(null)

    const isDisable = useMemo(() => me.userId !== item.createdByUserId, [me, item.createdByUserId])

    const upsert = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            handlerUpsertComment.current = handler.upsertComment(item, item.candidacyId || item.huntId, item.boardCommentId)
            const comment = await handlerUpsertComment.current.fetch()
            setStatus(Status.RESOLVED)
            onUpdate(comment)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case NotImplementedError:
                    break
                case InvalidEntityError:
                    setErrorField(/** @type {InvalidEntityError<ErrorBoardComment>} */(error).errorField)
                    setStatus(Status.REJECTED)
                    break
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [item, handler, onUpdate])

    const remove = useCallback(async () => {
        if (!item.boardCommentId) {
            onDelete()
            return
        }

        try {
            setStatus(Status.PENDING)
            handlerRemoveComment.current = handler.removeCommentById(item.candidacyId || item.huntId, item.boardCommentId)
            await handlerRemoveComment.current.fetch()
            setStatus(Status.RESOLVED)
            onDelete()
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [item, handler, onDelete])

    const togglePinned = useCallback(async () => {
        try {
            setStatus(Status.PENDING)
            handlerTogglePin.current = handler.patchPin(item.candidacyId || item.huntId, item.boardCommentId, { isPinned: !item.isPinned })
            const boardItem = await handlerTogglePin.current.fetch()
            setStatus(Status.RESOLVED)
            onUpdate(boardItem)
        } catch (error) {
            switch (error?.constructor) {
                case CancelRequestError:
                case UnauthorizedError:
                case InvalidEntityError: break
                case NotImplementedError:
                default:
                    // eslint-disable-next-line no-console
                    console.error(error)
                    setStatus(Status.REJECTED)
                    break
            }
        }
    }, [item, handler, onUpdate])

    // Cancel request on dismount
    useEffect(() => () => {
        handlerUpsertComment?.current?.cancel()
        handlerRemoveComment?.current?.cancel()
        handlerTogglePin?.current?.cancel()
    }, [])

    return (
        <div className={styles['board-detail-panel-comment']}>
            <div className={styles['board-detail-panel-comment-inputs']}>
                <TextField
                    value={item.date ? Time(item.date).getLocaleDateString() : ''}
                    readOnly={isDisable}
                    disabled={status === Status.PENDING}
                    onChange={(ev, newVal) => onUpdate({ ...item, date: isValidDate(new Date(newVal)) ? /** @type {any} */(new Date(newVal)) : '' })}
                    errorMessage={errorField.date}
                    type="date"
                    prefix="Date :"
                />
                <TextField
                    value={item.content}
                    errorMessage={errorField.content}
                    disabled={status === Status.PENDING}
                    multiline
                    autoAdjustHeight
                    styles={{
                        field: { textTransform: 'uppercase' },
                    }}
                    onChange={(ev, newvalue) => onUpdate({ ...item, content: newvalue })}
                    readOnly={isDisable}
                    rows={4}
                    description={`Créé par ${[item.createdByUser?.lastname, item.createdByUser?.firstname].filter(x => x).join(' ')}, `
                        + `le ${item.createdAt
                            ? Time(item.createdAt).getCleanDate({
                                year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
                            })
                            : ''} ${' avec le statut "'}${item.status || ''}".`}
                />
            </div>
            {!isDisable && (
                <div
                    className={styles['board-detail-panel-comment-actions']}
                >
                    <IconButton
                        iconProps={{ iconName: item.isPinned ? 'Unpin' : 'Pinned' }}
                        title={item.isPinned ? 'Retirer pense-bête' : 'Marquer comme pense-bête'}
                        onClick={() => togglePinned()}
                        disabled={status === Status.PENDING || !item.boardCommentId}
                    />
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        title="Supprimer"
                        onClick={() => remove()}
                        disabled={status === Status.PENDING}
                    />
                    <IconButton
                        iconProps={{ iconName: 'Save' }}
                        title="Enregistrer"
                        onClick={() => upsert()}
                        disabled={status === Status.PENDING}
                    />
                </div>
            )}
        </div>
    )
}

export default memo(BoardDetailPanelComment)
